/* @flow */
import t from 'counterpart';
import styled from '@emotion/styled';

import { ThemeProvider } from '@eyeem-ui/theme';
import { Text, Box, Flex } from '@eyeem-ui/atoms';
import { Chevron } from '@eyeem-ui/icons';

import Link from '../../../link/';

import { getSearchUrl } from '../../../../../helpers/search';
import { track } from '../../../../../helpers/tools';
import { getUrlLocalized } from '../../../../../helpers/localization';
import { SEARCH_ILLUSTRATION_BASE_URL_CONST } from '../../../../../constants/misc';
import withRouter from '../../../../../helpers/hoc/withRouter';

const PageNumber = styled(Link)`
  padding-right: 12px;
  padding-left: 12px;
  display: inline-block;
  position: relative;
  text-align: center;
  ${({ reducedSpacing }) =>
    reducedSpacing ? 'margin: 6px 0;' : 'margin: 6px 3px;'};
  ${({ isCurrentPage, theme }) =>
    isCurrentPage &&
    `&:after{
      height: 35px;
      width: 35px;
      content:'';
      background-color: ${theme.colors.grey40};
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      z-index: -1}`}
`;

const StyledIconWrapper = styled(Box)`
  border-radius: 50%;
  background-color: ${({ isLastPage, isFirstPage, theme }) =>
    isLastPage || isFirstPage ? theme.colors.grey40 : theme.colors.grey50};
`;

type Props = {
  totalPages: number,
  searchData: SearchData,
  isOnSearch: boolean,
  isMobile: boolean,
  initialUrl: string,
  language: string,
} & WithRouterProps;

const Pagination = ({
  totalPages,
  searchData,
  isOnSearch,
  isMobile,
  initialUrl,
  language,
  query,
  getPath,
}: Props) => {
  const currentPage = Number(query.page) || 1;
  const trackPagination = (trackingData) => track(trackingData);

  const getPageNumber = (value: number | string) => {
    if (value === 'previous') return parseInt(currentPage, 10) - 1;
    if (value === 'next') return parseInt(currentPage, 10) + 1;
    return value;
  };

  const getPageHref = (value: number | string) => {
    const isIllustrationSearch = getPath().includes(
      SEARCH_ILLUSTRATION_BASE_URL_CONST
    );
    const page = getPageNumber(value);
    if (isIllustrationSearch) {
      return getUrlLocalized(`${getPath()}?page=${page}`);
    }
    return isOnSearch && searchData?.q
      ? getUrlLocalized(
          getSearchUrl(searchData, {
            page,
          }),
          language
        )
      : `${getUrlLocalized(initialUrl, language)}?page=${page}`;
  };

  // The pagination is displayed as follows: if the max pages is 10 or lower we display only page numbers
  // On page 1: 1 2 3 4 5 6 7 8 ... 100 Next
  // from page 2 to 6: Prev 1 2 3 4 5 6 7 8 ... 100 Next
  // The pagination from page 7 to totalPages-5 is displayed as follows: Prev 1 ... 3 4 5 6 7 8 9 10 ... 100 Next
  // The pagination from totalPages-4 to totalPages is displayed as follows: Prev 1 ... 93 94 95 96 97 98 99 100 Next
  const getAvailablePages = () => {
    if (!isMobile) {
      const pages = [1];
      if (totalPages <= 10) {
        let i = 2;
        while (i <= totalPages) {
          pages.push(i);
          i++;
        }
        return pages;
      }
      if (currentPage < 5) {
        let i = 2;
        while (i < 9 && i < totalPages) {
          pages.push(i);
          i++;
        }
      }
      if (currentPage > 5) pages.push(t('grid.pagination.dots'));
      if (currentPage >= 5) {
        let i = currentPage - 3;
        if (currentPage + 3 > totalPages) {
          i = totalPages - 7;
        }
        while (i <= currentPage + 3 && i < totalPages) {
          pages.push(i);
          i++;
        }
      }

      if (currentPage < totalPages - 4) pages.push(t('grid.pagination.dots'));

      pages.push(totalPages);
      return pages;
    }

    return [t('grid.pagination.page'), currentPage];
  };

  return (
    <ThemeProvider>
      <Flex py={5} justifyContent="center" alignItems="center">
        {!isMobile && currentPage !== 1 && (
          <Box pr={4}>
            <Link
              href={getPageHref('previous')}
              onClick={() =>
                track({
                  eventType: t('tracking.eventType.inbound'),
                  eventAction: t('tracking.eventAction.button'),
                  eventName: t('grid.pagination.prevPage.eventName'),
                  eventLabel: t('grid.pagination.prevPage.label'),
                  eventPosition: t('grid.pagination.eventPosition'),
                  eventOption: `pageIndex=${getPageNumber('previous')}`,
                })
              }>
              <Text bold>{t('grid.pagination.prevPage.label')}</Text>
            </Link>
          </Box>
        )}
        {isMobile && (
          <Box pr={3}>
            <Link
              href={currentPage !== 1 ? getPageHref('previous') : null}
              onClick={() =>
                track({
                  eventType: t('tracking.eventType.inbound'),
                  eventAction: t('tracking.eventAction.button'),
                  eventName: t('grid.pagination.prevPage.eventName'),
                  eventLabel: t('grid.pagination.prevPage.label'),
                  eventPosition: t('grid.pagination.eventPosition'),
                  eventOption: `pageIndex=${getPageNumber('previous')}`,
                })
              }>
              <StyledIconWrapper isFirstPage={currentPage === 1} p={2}>
                <Box p={1}>
                  <Chevron rotate="left" color="black" />
                </Box>
              </StyledIconWrapper>
            </Link>
          </Box>
        )}
        {!isMobile && (
          <Box>
            {getAvailablePages().map((page) => {
              if (typeof page === 'number') {
                return (
                  <PageNumber
                    key={`number${page}`}
                    isCurrentPage={currentPage === page}
                    px={3}
                    href={getPageHref(page)}
                    onClick={() =>
                      trackPagination({
                        eventType: t('tracking.eventType.inbound'),
                        eventAction: t('tracking.eventAction.button'),
                        eventName: t('grid.pagination.nextPage.eventName'),
                        eventLabel: page,
                        eventPosition: t('grid.pagination.eventPosition'),
                        eventOption: `pageIndex=${page}`,
                      })
                    }>
                    <Text bold>{page}</Text>
                  </PageNumber>
                );
              }

              if (page === t('grid.pagination.dots')) {
                return (
                  <PageNumber key={`page-${page}`} px={3}>
                    <Text bold>{page}</Text>
                  </PageNumber>
                );
              }

              return (
                <PageNumber reducedSpacing key={`reduced${page}`} px={3}>
                  {page}
                </PageNumber>
              );
            })}
          </Box>
        )}
        {!isMobile && currentPage < totalPages && (
          <Box pl={4}>
            <Link
              href={getPageHref('next')}
              onClick={() =>
                track({
                  eventType: t('tracking.eventType.inbound'),
                  eventAction: t('tracking.eventAction.button'),
                  eventName: t('grid.pagination.nextPage.eventName'),
                  eventLabel: t('grid.pagination.nextPage.label'),
                  eventPosition: t('grid.pagination.eventPosition'),
                  eventOption: `pageIndex=${getPageNumber('next')}`,
                })
              }>
              <Text bold>{t('grid.pagination.nextPage.label')}</Text>
            </Link>
          </Box>
        )}
        {isMobile && (
          <Box pl={3}>
            <Link
              href={currentPage !== totalPages ? getPageHref('next') : null}
              onClick={() =>
                track({
                  eventType: t('tracking.eventType.inbound'),
                  eventAction: t('tracking.eventAction.button'),
                  eventName: t('grid.pagination.nextPage.eventName'),
                  eventLabel: t('grid.pagination.nextPage.label'),
                  eventPosition: t('grid.pagination.eventPosition'),
                  eventOption: `pageIndex=${getPageNumber('next')}`,
                })
              }>
              <StyledIconWrapper isLastPage={currentPage === totalPages} p={2}>
                <Box p={1}>
                  <Chevron rotate="right" color="black" />
                </Box>
              </StyledIconWrapper>
            </Link>
          </Box>
        )}
      </Flex>
    </ThemeProvider>
  );
};

export default withRouter(Pagination);
