/* @flow */
import { connect } from 'react-redux';
import { compose } from 'redux';
import withRouter from '../../../../../helpers/hoc/withRouter';
import Pagination from './pagination.jsx';

import { fullUrlParamsToSearchData } from '../../../../../helpers/search';

import {
  getAuthUser,
  getIsMobile,
  getLanguage,
} from '../../../../../reducers/';

const mapStateToProps = (state, ownProps) => {
  const authUser = getAuthUser(state);

  return {
    searchData: fullUrlParamsToSearchData(
      ownProps.getPath(),
      ownProps.query,
      authUser
    ),
    isMobile: getIsMobile(state),
    language: getLanguage(state),
  };
};

export default compose(withRouter, connect(mapStateToProps))(Pagination);
